<template>
<div class="app-container">
  <el-row v-loading="loading" element-loading-text="Cargando..." :gutter="10" type="flex" justify="center">
    <el-col :span="12">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <strong><span>Corregir Producto</span></strong>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">Operation button</el-button> -->
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
              <el-form-item label="Id producto" >
                <ValidationProvider name="id producto" rules="required|integer" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.id_product_sh"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Clave SAT Producto/Servicio">
                <ValidationProvider name="clave sat prod/serv" rules="required" v-slot="{ errors }">
                  <el-autocomplete
                    class="my-autocomplete"
                    v-model="clave_prod_serv"
                    :fetch-suggestions="querySearchProdServKeys"
                    placeholder=""
                    :trigger-on-focus="false"
                    @select="handleSelectProdServKeys">
                    <template slot-scope="{ item }">
                      <div class="name">{{ item.Valor }} - {{ item.Nombre }}</div>
                    </template>
                  </el-autocomplete>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Clave SAT Unidad">
                <ValidationProvider name="clave sat unidad" rules="required" v-slot="{ errors }">
                 <el-autocomplete
                    class="my-autocomplete"
                    v-model="clave_unidad"
                    :fetch-suggestions="querySearchUnitKeys"
                    placeholder=""
                    :trigger-on-focus="false"
                    @select="handleSelectUnitKeys">
                    <template slot-scope="{ item }">
                      <div class="name">{{ item.Valor }} - {{ item.Nombre }}</div>
                    </template>
                  </el-autocomplete>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Clave SAT Objeto de Impuesto">
                <ValidationProvider name="clave sat objeto de impuesto" rules="required" v-slot="{ errors }">
                <el-autocomplete
                    class="my-autocomplete"
                    v-model="clave_objImp"
                    :fetch-suggestions="querySearchObjImpKeys"
                    placeholder=""
                    :trigger-on-focus="false"
                    @select="handleSelectObjImpKeys">
                    <template slot-scope="{ item }">
                      <div class="name">{{ item.Valor }} - {{ item.Nombre }}</div>
                    </template>
                  </el-autocomplete>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Título">
                <ValidationProvider name="título" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.title" ></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Vendor">
                <ValidationProvider name="vendor" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.vendor"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item label="Tipo de producto">
                <el-input size="mini" v-model="form.product_type"></el-input>
              </el-form-item>
              <el-form-item label="Estatus">
                <ValidationProvider name="estatus" rules="required" v-slot="{ errors }">
                  <el-input size="mini" v-model="form.status"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
              <el-form-item>
              <el-button
                @click.stop.prevent="onFix"
                type="primary"
                style="margin-top: 20px;"
                icon="el-icon-upload2" :disabled="invalid">
                Corregir</el-button>
              </el-form-item>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>
<script>

import { getError, fixProduct } from '@/api/shopifyProduct'
import { sendToInvoice } from '@/api/shopify'
import { getProdServKeys, getUnitKeys, getObjImpKeys } from '@/api/keys'

var idProduct
var idTransaction

export default {
  data () {
    return {
      form: {
        id_product_sh: '',
        sat_prod_serv: '',
        sat_unidad: '',
        sat_objImp: '',
        title: '',
        vendor: '',
        product_type: '',
        status: ''
      },
      clave_prod_serv: '',
      clave_unidad: '',
      clave_objImp: '',
      loading: false,
      loadingDelete: false,
      inputVisible: false,
      inputValue: '',
      prodServKeys: [],
      unitKeys: [],
      objImpKeys: []
    }
  },
  mounted () {
    idTransaction = this.$route.query.idT
    idProduct = this.$route.query.idP
    console.log(idTransaction)
    console.log(idProduct)
    this.loading = true
    this.fetchData()
    this.handleProdServKeys()
    this.handleUnitKeys()
    this.handleObjImpKeys()
    this.loading = false
  },
  methods: {
    async fetchData () {
      this.form.id_product_sh = idProduct
      await getError(idProduct)
        .then((response) => {
          this.form.title = response.data.title
          this.form.id_product_sh = response.data.id_product_sh
          this.form.vendor = response.data.vendor
          this.form.sat_prod_serv = response.data.sat_prod_serv
          this.clave_prod_serv = response.data.sat_prod_serv
          this.form.sat_unidad = response.data.sat_unidad
          this.clave_unidad = response.data.sat_unidad
          this.form.sat_objImp = response.data.sat_objImp
          this.clave_objImp = response.data.sat_objImp
          this.form.product_type = response.data.product_type
          this.form.status = response.data.status
        })
        .catch(this.responseCatch)
        .finally()
    },
    async handleProdServKeys () {
      await getProdServKeys()
        .then((response) => {
          this.prodServKeys = response.data
        })
        .catch(this.responseCatch)
        .finally()
    },
    async handleUnitKeys () {
      await getUnitKeys()
        .then((response) => {
          this.unitKeys = response.data
        })
        .catch(this.responseCatch)
        .finally()
    },
    async handleObjImpKeys () {
      await getObjImpKeys()
        .then((response) => {
          this.objImpKeys = response.data
        })
        .catch(this.responseCatch)
        .finally()
    },
    async onFix () {
      await fixProduct(this.form, idProduct)
        .then((response) => {
          this.$message({
            showClose: true,
            message: 'Done!',
            type: 'success'
          })
          this.handleInvoice()
        })
        .catch(this.responseCatch)
    },
    async handleInvoice () {
      await sendToInvoice(idTransaction)
        .then((response) => {
          if (response.data === true) {
            this.$message({
              showClose: true,
              message: 'Transacción enviada a facturar exitosamente',
              type: 'success'
            })
          } else {
            this.$message.error('Oops, algo a salido mal.')
          }
        })
        .catch(this.responseCatch)
        .finally(this.$router.push({ name: 'shopify' }))
    },
    querySearchProdServKeys (queryString, cb) {
      var links = this.prodServKeys
      var results = queryString ? links.filter(this.createFilterProdServKeys(queryString)) : links
      // call callback function to return suggestions
      cb(results)
    },
    createFilterProdServKeys (queryString) {
      return (link) => {
        return ((link.Nombre.toLowerCase().indexOf(queryString.toLowerCase()) === 0) || (link.Valor.toLowerCase().indexOf(queryString.toLowerCase()) === 0))
      }
    },
    handleSelectProdServKeys (item) {
      this.clave_prod_serv = item.Valor + ' - ' + item.Nombre
      this.form.sat_prod_serv = item.Valor
    },
    querySearchUnitKeys (queryString, cb) {
      var links = this.unitKeys
      var results = queryString ? links.filter(this.createFilterUnitKeys(queryString)) : links
      // call callback function to return suggestions
      cb(results)
    },
    createFilterUnitKeys (queryString) {
      return (link) => {
        return ((link.Nombre.toLowerCase().indexOf(queryString.toLowerCase()) === 0) || (link.Valor.toLowerCase().indexOf(queryString.toLowerCase()) === 0))
      }
    },
    handleSelectUnitKeys (item) {
      this.clave_unidad = item.Valor + ' - ' + item.Nombre
      this.form.sat_unidad = item.Valor
    },
    querySearchObjImpKeys (queryString, cb) {
      var links = this.objImpKeys
      var results = queryString ? links.filter(this.createFilterObjImpKeys(queryString)) : links
      // call callback function to return suggestions
      cb(results)
    },
    createFilterObjImpKeys (queryString) {
      return (link) => {
        return ((link.Nombre.toLowerCase().indexOf(queryString.toLowerCase()) === 0) || (link.Valor.toLowerCase().indexOf(queryString.toLowerCase()) === 0))
      }
    },
    handleSelectObjImpKeys (item) {
      this.clave_objImp = item.Valor + ' - ' + item.Nombre
      this.form.sat_objImp = item.Valor
    }
  }
}
</script>

<style>
 .size-form-item > .el-form-item {
    margin: 0;
  }
  .size-form-item > .el-form--label-top .el-form-item__label {
    padding: 0;
  }
  .my-autocomplete {
    width: 500px;
  }
</style>
